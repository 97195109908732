


.product-item {
  cursor: pointer;
}

.pluginWrap .plugin-item .lineitem.l-prio {
  padding-left: 32px;
  padding-right: 32px;
}

.pluginWrap span.roundBadge {
  border: 0;
  background: #969696;
  width: 30px;
  line-height: 30px;
  height: 30px;
}
.titlelong {

    font-size: 14px;

}

