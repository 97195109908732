
body {
  .vue-universal-modal.whiteModal {
    background: #333;

  }
}

.classEven .even {

  background: #eaeaea;


}
tr.old_agenda {
  opacity: .5;
}
.readonlyRow{
  border-bottom: 1px solid #eee;
}
.whiteModal .modal-body .readonlyRow {
  /* font-size: 22px; */
  margin-bottom: 24px !important;
  /* display: inline-block; */
  float: left;
  background: #eee;
  padding: 24px !important;
}

.whiteModal .modal-body .readonlyRow .col-9 {
  font-size: 20px;
  line-height: 32px;
}
