
.nav-pills {
  .nav-item {
    padding: 0 1rem 0 1rem;
    .nav-link {
      border: .125rem solid #5e72e4 !important;
      &.active {
        background-color: #5e72e4 !important;
        color: #fff !important;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
