
// base font size - adjust it to <p> size
$base-font-size: 16px;
$base-font-math: 16;
$base-font-family: 'Roboto', sans-serif;
$icon: bare;
$fontawesome: 'Font Awesome 6 Duotone';
/* Colors */
$black: #000;
$base: #212529;
$link: #0088CC;
$link_hover: #329dce;
$bgbox: #f4f4f4;
$main: #329dce;
$dark: #3C3C3B;
$light: #fff;
$bginput: #fbfbfb;
$border: #e8e8e8;
$placeholder: #adadad;
$bg: #f4f4f4;
$h1: #3C3C3B;
$h2: #3C3C3B;
$h3: #3C3C3B;
$h4: #3C3C3B;
$footer: #3C3C3B;
$header: #fff;
$green: #3B8130;
$green1: #A0BC9C;
$green2: #D5E1D3;
$blue: #0088CC;
$blue1: #99cfeb;
$brightblue: $blue1;
$blue2: #DBEDF7;
$blue3: #EEF7FB;
$blue4: #EEF7FB;
$red: #b21f28;
$red1: #E0A5A9;
$red2: #F8EDEE;
$orange: #fd7e14;
$orange1: #FEC799;
$orange2: #fae7e1;
$purple: #6f42c1;
$purple1: #BBA9DC;
$purple2: #F6F3FB;
$web: #38aaff;
$print: #ff4e3d;
$foto: #ffe029;
$blog: #2dc549;
$grey: #6C757D;
$grey1: #b1b1b1;
$grey2: #dcdcdc;
$grey3: #f4f4f4;
$grey4: #f8f8f8;

// Radio/Chheckbox Styles
$md-radio-checked-color: $main;
$md-radio-border-color: $border;
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 5px;
// bootstrap resets
.btn-outline-success {
	--bs-btn-hover-color: white;
}

// Styles

$br: 7px;
$box-shadow: 0 0 10px -1px #333;

// Icons
$icon-search: "\f002";
$icon-envelope-o: "\f003";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-user: "\f007";
$icon-check: "\f00c";
$icon-close: "\f00d";
$icon-remove: "\f00d";
$icon-times: "\f00d";
$icon-cog: "\f013";
$icon-gear: "\f013";
$icon-trash-o: "\f014";
$icon-home: "\f015";
$icon-file-o: "\f016";
$icon-tag: "\f02b";
$icon-book: "\f02d";
$icon-bookmark: "\f02e";
$icon-print: "\f02f";
$icon-camera: "\f030";
$icon-pencil: "\f040";
$icon-chevron-left: "\f053";
$icon-chevron-right: "\f054";
$icon-arrow-right: "\f061";
$icon-exclamation-circle: "\f06a";
$icon-gift: "\f06b";
$icon-eye: "\f06e";
$icon-calendar: "\f073";
$icon-twitter-square: "\f081";
$icon-facebook-square: "\f082";
$icon-key: "\f084";
$icon-cogs: "\f085";
$icon-gears: "\f085";
$icon-thumbs-o-up: "\f087";
$icon-heart-o: "\f08a";
$icon-sign-out: "\f08b";
$icon-thumb-tack: "\f08d";
$icon-external-link: "\f08e";
$icon-upload: "\f093";
$icon-phone: "\f095";
$icon-phone-square: "\f098";
$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-unlock: "\f09c";
$icon-credit-card: "\f09d";
$icon-hand-o-right: "\f0a4";
$icon-arrow-circle-right: "\f0a9";
$icon-globe: "\f0ac";
$icon-group: "\f0c0";
$icon-users: "\f0c0";
$icon-chain: "\f0c1";
$icon-link: "\f0c1";
$icon-floppy-o: "\f0c7";
$icon-save: "\f0c7";
$icon-truck: "\f0d1";
$icon-caret-right: "\f0da";
$icon-envelope: "\f0e0";
$icon-linkedin: "\f0e1";
$icon-rotate-left: "\f0e2";
$icon-undo: "\f0e2";
$icon-gavel: "\f0e3";
$icon-legal: "\f0e3";
$icon-comment-o: "\f0e5";
$icon-comments-o: "\f0e6";
$icon-bolt: "\f0e7";
$icon-flash: "\f0e7";
$icon-umbrella: "\f0e9";
$icon-lightbulb-o: "\f0eb";
$icon-suitcase: "\f0f2";
$icon-coffee: "\f0f4";
$icon-angle-right: "\f105";
$icon-laptop: "\f109";
$icon-tablet: "\f10a";
$icon-mobile: "\f10b";
$icon-mobile-phone: "\f10b";
$icon-quote-left: "\f10d";
$icon-quote-right: "\f10e";
$icon-folder-open-o: "\f115";
$icon-chevron-circle-right: "\f138";
$icon-instagram: "\f16d";
$icon-long-arrow-right: "\f178";
$icon-arrow-circle-o-right: "\f18e";
$icon-fax: "\f1ac";
$icon-share-alt: "\f1e0";
$icon-wifi: "\f1eb";
$icon-street-view: "\f21d";
$icon-facebook-official: "\f230";
$icon-pinterest-p: "\f231";
$icon-whatsapp: "\f232";
$icon-amazon: "\f270";
$icon-map-o: "\f278";
$icon-envira: "\f299";
$icon-telegram: "\f2c6";


// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1300px !default;
$hd-screen: 1650px !default;
$screen: "only screen" !default;

$small: "(min-width: #{$small-screen})" !default;
$small-max: "(max-width: #{$small-screen - 1})";
$medium: "(min-width:#{$medium-screen})" !default;
$medium-max: "(max-width: #{$medium-screen - 1})";
$large: "(min-width:#{$large-screen})" !default;
$large-max: "(max-width: #{$large-screen - 1})";
$xlarge: "(min-width:#{$xlarge-screen})" !default;
$xlarge-max: "(max-width: #{$xlarge-screen - 1})";
$hd: "(min-width:#{$hd-screen})" !default;
$landscape: "(orientation: landscape)" !default;
$portrait: "(orientation: portrait)" !default;


