.projects-item {
	> .card > .card-header {
		border-radius: 0;
		h6 {
			margin-top: 6px;
		}
	}
	.projectInfoWrap > .projectWrap {
		padding-top: 5px;
		.l-avatar img {
			width: 20px;
			height: 20px;
		}
	}
	.taskWrap, .projectWrap {
		transition: .5s all ease 0s;
		cursor: pointer;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		&:last-child {
			border-bottom: 0;
		}
		&:hover, &.active {
			background: $bgbox;
			.lineitem.l-check {
				i {
					opacity: 1;
				}
			}
		}
		.d-flex {
			width: 100%;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			&.l-title {
				width: 52%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 12px;
				padding-right: 12px;
				display: none;
				@media #{$medium}{
					display: block;
				}
			}
			&.l-date {
				&.due {
					color: $red;
					font-weight: 500;
				}
				&.today {
					color: $green;
					font-weight: 500;
				}
			}
			&.l-prio {
				width: 55px;
				text-align: center;
			}
			&.l-state {
				width: 55px;
				text-align: center;
			}
			&.l-projectstate {
				width: 120px;
			}
			&.l-hours {
				width: 75px;
			}
			&.l-check {
				i {
					opacity: .4;
				}
				&:hover {
					color: $link;
				}
			}
		}
	}
	.taskDetails, .projectDetails {
		padding-top: 12px;
		@include clear();
		.taskInfos, .projectInfos {
			float: right;
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			ul.infoList {
				li {
					label {
						font-weight: 500;
						@include font-size(14, 1.8);
						width: 100px;
					}
				}
			}
		}
		.taskDesc {
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			width: 500px;
			max-width: 100%;
		}
		.projectDesc {
			width: 100%;
			padding-top: 24px;
		}
	}
}

.repeatForm {
	> .card {
		border-radius: 0;
		border: 0;
		.card-header {
			border-radius: 0;
			text-transform: capitalize;
		}
	}
}

.ck.ck-editor {
	margin-bottom: 12px;
}

.firstToUpper {
	text-transform: capitalize;
}

.project-list.alllistView {
	.projectLine {
		.lineitem {
			&.l-title{
				white-space: normal !important;
				overflow: auto !important;
			}
			&.l-issue_count {
				display: none;
				@media #{$hd}{
					display: flex;
				}
				width: 85px;
				text-align: center;
				@include font-size(14);
			}
			&.l-customer {
				@include font-size(12);
			}
			&.l-tags{
				width: 120px;
			}
			&.l-actions{
				span.iconAction{
					color: $purple;
					&:hover{
						background: $purple;
						color: $light;
						.incNumber{
							background: $base;
							color: $light;
						}
					}
				}
			}

		}
		.incNumber {
			font-size: 11px;
			font-size: 0.6875rem;
			line-height: 1.4;
			position: absolute;
			z-index: 1;
			width: 16px;
			display: block;
			height: 16px;
			text-align: center;
			background: #F6F3FB;
			line-height: 14px;
			border-radius: 100%;
			right: 0;
			top: 0;
			white-space: nowrap;
		}
	}
}
ul.todo-list {
	list-style: none;
	margin-left: 0;
}

ul.todo-list input {
	margin-right: 6px;
}
.incNumber {
	@include font-size(11);
	position: absolute;
	z-index: 1;
	width: 16px;
	display: block;
	height: 16px;
	text-align: center;
	background: $grey2;
	line-height: 14px;
	border-radius: 100%;
	right: 0;
	top: 0;
	white-space: nowrap;
}
.btn:hover {
	.incNumber {
		background: $base;
		color: $light;
	}
}
