
.smallerBtn {
  padding: 2px 5px !important;
  font-size: 13px !important;
  font-weight: normal !important;
  border-radius: 3px !important;
  margin-bottom: 4px !important;
}

.l-infos {
  span.badge {
    line-height: 15px;
    font-size: 12px !important;
    @media (min-width: 1600px) {
      min-width: 115px;
    }
    &.badge-secondary {
      background: #969696;
      border: 1px solid #969696;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.badge-outline {
      border: 1px solid #969696;
      color: #969696;
      background: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.l-smallbadge {
    span.badge {
      line-height: 15px;
      @media (min-width: 1600px) {
        min-width: 50px;
      }
    }
  }
}
.badgeCountRow {
  span.badgeCount {
    background: #fff;
  }

  button.btn.btn-outline-secondary.me-1.smallerBtn {
    padding: 2px 4px !important;
    border: 0;
    background:#eee;
    &:hover{
      background:#ddd;
      color: #333;
    }
  }

  button.btn.btn-outline-secondary.me-1.smallerBtn.btn-success {
    border: 0;
    background:#249b3a;
    &:hover{
      color: #fff;
    }
  }

  button.btn.btn-outline-secondary.me-1.smallerBtn .badgeCount {

    display: inline-block;
    width: 30px;
    text-align:center;
    background: #ffffff;
    color: #000;
    font-size: 10px;
    margin-left: 12px;
    padding: 3px;
    margin-right: -2px;
  }
}
.product-item {
  cursor: pointer;
}

.plugins.dfb .card-body hr {
  margin-top: 8px;
  margin-bottom: 12px;
}

.pluginWrap span.roundBadge {
  background: #969696;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.appView.plugins.dfb .singleRequest {
  .checkbox label {
    &:before {
      top: 2px;
      width: 12px;
      height: 12px;
    }
    &:after{
      top: 0px;
      width: 6px;
      height: 6px;
      left: 1px;
      font-size: 12px;
    }
  }
}
