@media #{$small-max} {
	.btn-hide-mobile, .overviewCounter {
		display: none;
	}
	aside.navbar.closed {
		width: 260px;
		min-width: 260px;
		right: auto;
		left: -260px;
		position: absolute;
		.actionIcon.single.outter {
			position: absolute;
			top: 10px;
			left: 259px;
		}
	}
	.appContainer{
		padding-left: 12px;
		padding-right: 12px;
	}
	aside.navbar.closed ul.navbar-nav li.nav-item span.nav-link-text {
		display: block;
	}
	aside.sidenav.closed ~ .main-content {

	}
	.customer-list .customer-item.activeCustomer .customerLineWrap .lineitem.l-avatar {
		display: none !important;
	}
	.customer-list .customer-item .customerLineWrap .lineitem {
		&.l-company {
			span.customerInfo{
				@media #{$small-max}{
					width: 100%;
				}
			}
		}
		&.l-akquise, &.actions {
			display: none;
		}
		&.l-checkbox {
			margin-right: 2px;
			padding-left: 2px;
			min-width: 24px;
			padding-bottom: 3px;
		}
	}
	.customer-list .customer-item.headline .customerLineWrap .lineitem.l-checkbox {
		padding-top: 0;
	}
	.customerContent {
		h6.card-header .btn {
			font-size: 0;
			i {
				font-size: 12px;
			}
		}
		.card-body {
			padding: 6px;
		}
	}
	ul.tabNav {
		flex-direction: column;
		li {
			text-align: left;
			i {
				margin-right: 6px;
			}
			br {
				display: none;
			}
			small {
				display: inline;
			}
			&.documents, &.events {
				display: none;
			}
		}
	}
}
