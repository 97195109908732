
.newsletterLineWrap .lineitem {
  width: 100px !important;
  text-align: center !important;
  display: inline !important;
  &.l-title,&.l-checkbox {
    text-align: left !important;
  }
  &.l-date .date {
    font-size: 14px;
  }
  .fa-circle-check {
    color: green
  }
}
