.uploader {
	.fileItem {
		padding-right: 12px;
		.fileInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			cursor: pointer;
			border-bottom: 1px solid $border;
			&:hover {
				background: $bgbox;
				.arrow {
					color: $blue;
				}
				.price {
					color: $black;
				}
			}
			li {
				&.arrow {
					width: 24px;
					text-align: center;
					@include font-size(16, 1.5);
					margin-right: 12px;
				}
				&.title {
					width: 250px;
				}
				&.quantity {
					margin-left: 12px;
					text-align: center;
				}
				&.price {
					margin-left: auto;
					text-align: left;
					font-weight: bold;
					color: $dark;
				}
				&.unit {
					margin-left: 6px;
					@include font-size(12, 2);
				}
				input {
					height: 20px;
				}
			}
		}
	}
}

.inDocument {
	.input-group {
		padding-right: 16px;
		input.fullPriceRender {
			height: 20px;
			font-size: 16px;
			margin-left: auto;
			font-weight: bold;
			text-align: right;
		}
		select {
			height: 20px;
			width: 100%;
			font-size: 14px;
		}
	}
	.fileItem {
		ul.fileInfoBar {
			li {
				padding-top: 6px;
				padding-left: 3px;
				padding-right: 3px;
				input {
					max-width: 100%;
					width: 100%;
					height: 20px;
					font-size: 14px;
				}
				&.quantity {
					width: 90px;
				}
				&.unit {
					width: 100px;
				}
				&.title {
					width: 400px;
				}
				&.final_price {
					input {
						text-align: right;
					}
				}
			}
		}
	}
}

.file-item {
	.fileLineWrap {
		padding-top: 12px;
		border-bottom: 1px solid $border;
		transition: .5s all ease 0s;
		padding-bottom: 9px;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		&:hover, &.active {
			background: $bgbox;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}

			&.l-title {
				width: 420px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
				&.l-doctitle {
					width: 440px;
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}
			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
			}
			&.l-unit {
				text-align: left;
				width: 100px;
				.badge {
					width: 100%;
				}
			}
			&.l-action {
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				.fa-x {
					display: inline-block;
				}
			}
			&.last {
				padding-right: 16px;
			}
		}
	}
	.file-list.card-body > .fileItem.activeFile {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		.fileLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		.card {
			@include clear();
			border: 0;
			border-top: 0;
			margin-bottom: 12px;
			.card-body {
				border: 0;
				border-radius: 0 0 $br $br;
				border-top: 0;
				margin-bottom: 12px;
				width: 100%;
			}
		}
		.fileContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
}

.invoiceWrap {
	.dp__main .dp__pointer.dp__input {
		padding-left: 7px;
		&::placeholder {
			@include font-size(16, 1.6);
		}
	}
}

.filesystemFullWrap {
	.breadcrumbs{
		margin-bottom: 12px;
	}
	.folders {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 12px;
		margin-bottom: 12px;
	}
	.documentbox {
		flex: 0 0 98%;
		@media #{$medium}{
			flex: 0 0 48%;
		}
		@media #{$xlarge}{
			flex: 0 0 23%;
		}
		@media #{$hd}{
			flex: 0 0 19%;
		}
		padding: 2px 10px;
		background: #eee;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
		display: flex;
		position: relative;
		align-items: center;
		span.icon {
			padding-right: 8px;
			i {
				font-size: 28px;
				line-height: 1;
			}
		}
		span.text {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		span.sumItems {
			font-weight: bold;
			@include font-size(12);
			position: absolute;
			top: 9px;
			left: 10px;
			text-align: center;
			color: $light;
			width: 28px;
		}
		&:hover {
			span.icon {
				color: $red;
			}
		}
	}
	.documentLineWrap {
		margin-bottom: 0;
		.documentline{
			&.unbalanced.type_out{
				background: $green2;
			}
			&.unbalanced.type_in{
				background: $red2;
			}
		}
		span.documentInfo {
			@include font-size(11, 1.5);
			display: block;
			font-weight: normal;
			white-space: pre;
			bottom: 0;
		}
		.lineitems {
			display: flex;
			align-items: center;
			width: 100%;
			border-bottom: 1px solid $border;
		}
		.lineitem {
			transition: .3s all ease 0s;
			padding-top: 8px;
			padding-bottom: 9px;
			padding-left: 6px;
			padding-right: 6px;
			@include font-size(14, 1.5);
			min-width: 32px;
			i.fa-duotone.fa-rectangle-xmark {
				color: $red;
			}
			&.l-checkbox {
				padding-left: 12px;
				padding-right: 6px;
				min-width: 51px;
			}
			&.l-title {
				width: 320px;
				text-overflow: ellipsis;
				overflow: hidden;
				font-weight: normal;
				@include font-size(14, 1.5);
				padding-right: 12px;
				cursor: pointer;
				.docinfo {
					line-height: 1.4;
					@media #{$xlarge}{
						display: block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.documentInfo {
					margin-top: 1px;
				}
				@media #{$hd}{
					width: 420px;
				}
				@media (min-width: 1910px) {
					width: 550px;
				}
			}
			&.l-account {
				width: 160px;
				&.content {
					@include font-size(12);
				}
			}
			&.l-avatar {
				text-align: center;
				padding-right: 12px;
				display: none;
				@media #{$xlarge}{
					display: block;
				}
			}
			&.l-size{
				width: 105px;
				text-align: right;
			}
			&.l-mime{
				width: 50px;
				text-align: center;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				cursor: pointer;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
				color: $grey;
				display: none;
				font-weight: normal;
				@include font-size(14);
				@media #{$hd}{
					display: block;
				}
			}
			&.l-company {
				width: 125px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				cursor: pointer;
				font-weight: 500;
				@include font-size(14, 1.8);
				max-width: 100%;
				padding-right: 12px;
				position: relative;
				@media #{$xlarge}{
					width: 245px
				}
			}
			&.l-category {
				width: 120px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: none;
				@media #{$hd}{
					display: block;
				}
			}
			&.l-date {
				@include font-size(14, 1.8);
				width: 105px;
				display: none;
				@media #{$xlarge}{
					display: block;
				}
			}
			&.l-netto, &.l-brutto {
				@include font-size(14, 1.8);
				width: 110px;
				text-align: right;
			}
			&.l-brutto {
				display: none;
				@media #{$hd}{
					display: block;
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				display: none;
				@media #{$large}{
					display: block;
				}
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
				display: none;
				@media #{$medium}{
					display: block;
				}
			}
			&.l-akquise {
				text-align: center;
			}
			&.l-state {
			}
			&.l-action{
				width: 140px;
			}
			&.l-type {
				text-align: center;
				width: 70px;
				display: none;
				@media #{$medium}{
					display: block;
				}
				.badge {
					width: 100%;
					background: transparent;
					text-align: left;
					border: 0;
					padding-left: 0;
				}
			}
			&.last {
				padding-right: 16px;
				i {
					cursor: pointer;
				}
			}
			.btn-outline-secondary {
				color: $purple;
				&:hover {
					background: $purple;
					color: $light;
				}
			}
		}
	}
	&.compactView{
		max-height: 250px;
		overflow-y: auto;
		overflow-x: hidden;
		.card-body{
			padding: 0;
			.documentLineWrap .lineitem.l-title{
				@include font-size(13, 1.5)
			}
		}

	}
}
