@media print {
	#sidenav-main {
		display: none;
	}
	aside.sidenav ~ main.main-content {
		margin-left: 0;
	}
	.card {
		// avoid pagebreak
		page-break-inside: avoid;
	}
	.table-responsive {
		max-height: 100% !important;
	}
	.container-fluid.accountingWrapper > .row > div > .bgCard.mb-4, .oposList .belege td.aktion, .oposList div:first-child > .row {
		display: none;
	}
	table.accountingDataTable.table.table-striped tr td:first-child,table.accountingDataTable.table.table-striped tr th:first-child{
		display: none;
	}
	.navbarTop.navbar#navbarBlur {
		display: none;
	}
	.card {
		border: 0;
		box-shadow: none;
		padding: 0;
	}
}
