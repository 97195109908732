
.oldSubmit table {
  width: 100%;
  margin-bottom: 48px;
  tr td:first-child {
    width: 200px;
    text-align: right;
    padding-right: 24px;
  }
}
