.appView, .modal-dialog {
	&.purpleView {
		.loadFullWrap {
			i {
				color: $purple;
			}
		}
		.btn-outline-primary {
			--bs-btn-color: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #6f42c1;
			--bs-btn-hover-border-color: #6f42c1;
			--bs-btn-active-bg: #6f42c1;
		}
		.btn-primary {
			--bs-btn-bg: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #BBA9DC;
			--bs-btn-hover-border-color: #BBA9DC;
		}
		hr {
			background: $purple;
		}
		textarea, input, select {
			&:focus {
				border-color: $purple;
			}
		}
		.form-check-input:checked {
			background-color: #c5b3e6;
			border-color: #6f42c1;
		}
		.form-check-input:focus{
			box-shadow: 0 0 0 0.25rem rgba(111, 66, 193, 0.25);
		}
		.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
			background-color: $purple;
		}
		.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
			border-color: $purple;
		}
		ul.tabNav {
			li {
				border-bottom-color: $purple1;
				&.active {
					border-bottom-color: $purple;
					color: $purple;
				}
				&:hover {
					color: $purple;
				}
			}
		}
		.card-header-inline {
			background: $purple2;
		}
		ul li::marker {
			color: $purple;
		}
		label.color {
			color: $purple1;
			display: block;
		}
		.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
			border-color: $purple;
		}
		.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
			border-color: $purple;
		}
		.infoRow {
			span i {
				cursor: pointer;
			}
			span:hover i {
				color: $purple;
			}
		}
		div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
			background: $purple;
			.multiselect__tag-icon {
				top: -2px;
				&:after {
					color: $light;
				}
			}
		}
		div.multiselect.small .multiselect__single {
			color: $purple;
		}
		div.multiselect {
			.multiselect__select {
				border-color: $purple;
				&:before {
					border-color: $purple transparent transparent transparent;
				}
			}
			&.multiselect--active {
				.multiselect__select {
					background: $purple;
					&:before {
						border-color: $light transparent transparent transparent;
					}
				}
				.multiselect__content-wrapper {
					border-color: $purple1;
					.multiselect__option--highlight {
						background: $purple2;
					}
				}
			}
		}
		div.multiselect .multiselect__tags {
			border-color: $purple1;
		}
		.dp__main.small .dp__pointer.dp__input, .dp__main .dp__pointer.dp__input {
			color: $purple;
			border: 1px solid $purple1;
		}
		.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons,
		.dp__main svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__input_icon.dp__input_icons {
			color: $purple;
			border: 1px solid $purple;
		}
		hr {
			background: $purple1;
		}
		.lineitem.last.ml-auto:hover i {
			color: $purple;
		}
		.page-item:not(.disabled) .page-link {
			color: $purple;
		}
		b.color {
			color: $purple !important;
		}
		.alert {
			&.alert-message, &.alert-info {
				border: 2px solid $purple;
				.close {
					border: 1px solid $purple;
					color: $purple;
				}
			}
		}
		div.multiselect {
			.multiselect__select {
				border-color: $purple;
			}
			&.multiselect--active {
				.multiselect__select {
					background: $purple;
				}
			}
			.multiselect-caret {
				background-color: $purple;
			}
			.multiselect-wrapper {
				border-color: $purple;
			}
		}
		ol li::marker {
			color: $purple;
		}
		div.multiselect .multiselect-wrapper .multiselect-tags span.multiselect-tag {
			background: $purple;
		}
	}
}

label.color {
	color: $blue1;
	display: block;
}

.form-control {
	&.purple {
		+ .input-group-append .btn-outline-primary {
			--bs-btn-color: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #6f42c1;
			--bs-btn-hover-border-color: #6f42c1;
			--bs-btn-active-bg: #6f42c1;
		}
	}
	&.red {
		+ .input-group-append .btn-outline-primary {
			--bs-btn-color: #b21f28;
			--bs-btn-border-color: #b21f28;
			--bs-btn-hover-bg: #b21f28;
			--bs-btn-hover-border-color: #b21f28;
			--bs-btn-active-bg: #b21f28;
		}
	}
	&.green {
		+ .input-group-append .btn-outline-primary {
			--bs-btn-color: #3B8130;
			--bs-btn-border-color: #3B8130;
			--bs-btn-hover-bg: #3B8130;
			--bs-btn-hover-border-color: #3B8130;
			--bs-btn-active-bg: #3B8130;
		}
	}
}

.multiselect-option.is-selected {
	background: $blue;
	&:hover, &.is-pointed {
		background: $blue;
	}
}

.btn-outline-primary {
	--bs-btn-color: #0088CC;
	--bs-btn-border-color: #0088CC;
	--bs-btn-hover-bg: #0088CC;
	--bs-btn-hover-border-color: #0088CC;
	--bs-btn-active-bg: #0088CC;
	--bs-btn-active-border-color: #0088CC;
	--bs-btn-active-color: #fff;
	--bs-btn-hover-color: #fff;
}

.btn-outline-primary.purple {
	--bs-btn-color: #6f42c1;
	--bs-btn-border-color: #6f42c1;
	--bs-btn-hover-bg: #6f42c1;
	--bs-btn-hover-border-color: #6f42c1;
	--bs-btn-active-bg: #6f42c1;
	--bs-btn-active-color: #fff;
	--bs-btn-hover-color: #fff;
}

.btn-outline-primary.red {
	--bs-btn-color: #b21f28;
	--bs-btn-border-color: #b21f28;
	--bs-btn-hover-bg: #b21f28;
	--bs-btn-hover-border-color: #b21f28;
	--bs-btn-active-bg: #b21f28;
	--bs-btn-active-color: #fff;
	--bs-btn-hover-color: #fff;
}

.loadFullWrap {
	i {
		color: $blue;
	}
}

.wrapper {
	span.btn-logout:hover {
		i {
			color: $blue;
		}
	}
	p.color {
		color: $blue1;
	}
	#filter_search {
		background: $light;
		border-color: $blue;
	}
	// Hook subviews here
	&.Tasks, &.TaskDetails, &.Events, &.EventDetails, &.EventTabs, &.TasksTabs, &.Projects, &.ProjectDetails {
		.btn-outline-primary {
			--bs-btn-color: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #6f42c1;
			--bs-btn-hover-border-color: #6f42c1;
			--bs-btn-active-bg: #6f42c1;
			--bs-btn-active-color: #fff;
			--bs-btn-hover-color: #fff;
		}
		.multiselect-option.is-selected {
			background: $purple;
			&:hover, &.is-pointed {
				background: $purple;
			}
		}
		.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
			background-color: $purple;
		}
		.btn-primary {
			--bs-btn-bg: #6f42c1;
			--bs-btn-border-color: #6f42c1;
			--bs-btn-hover-bg: #BBA9DC;
			--bs-btn-hover-border-color: #BBA9DC;
		}
		.form-control:focus, .form-select:focus {
			border-color: #6f42c1;
		}
		span.btn-logout:hover {
			i {
				color: $purple;
			}
		}
		.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
			border-color: $purple;
		}
		p.color {
			color: $purple1;
		}
		#filter_search {
			border-color: $purple;
		}
		.badge.bg-primary{
			background-color: $purple !important;
		}
		.dp__main.small .dp__pointer.dp__input {
			color: $purple;
			border: 1px solid $purple1;
		}
		.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
			color: $purple;
			border: 1px solid $purple;
		}
		.topMainIcons .actionIcon.double {
			background: $purple1;
		}
		.topMainIcons .actionIcon.double span.active {
			background: $purple;
		}
		.topMainIcons .actionIcon.outter .btn i {
			color: $purple;
		}
		div.multiselect .multiselect__select {
			border-color: $purple;
		}
		div.multiselect.multiselect--active .multiselect__select {
			background: $purple;
			&:before {
				border-color: $light transparent transparent transparent;
			}
		}
		div.multiselect.small .multiselect__single {
			color: $purple;
		}
		div.multiselect .multiselect__content-wrapper {
			border-color: $purple;
		}
		div.multiselect .multiselect__option--highlight {
			background: $purple2;
		}
		div.multiselect .multiselect__select:before {
			border-color: $purple transparent transparent transparent;
		}
		div.multiselect .multiselect__tags {
			border-color: $purple1;
		}
		div.multiselect.multiselect--active .multiselect__tags {
			border-color: $purple;
		}
		hr {
			background-color: $purple1;
		}
		.navbarTop .input-group-append .btn {
			border-right-color: $purple;
		}
		.lineitem.last.ml-auto:hover i {
			color: $purple;
		}
		div.multiselect {
			.multiselect__select {
				border-color: $purple;
			}
			&.multiselect--active {
				.multiselect__select {
					background: $purple;
				}
			}
			.multiselect-caret {
				background-color: $purple;
			}
			.multiselect-wrapper {
				border-color: $purple;
			}
		}
		ol li::marker {
			color: $purple;
		}
		div.multiselect .multiselect-wrapper .multiselect-tags span.multiselect-tag {
			background: $purple;
		}
		.page-link.active, .active > .page-link {
			background-color: $purple;
			border-color: $purple;
			color: $light !important;
		}
	}
}

.accountingWrapper, .appView.redView,#wrapper.ConfigYearplanConfigs, .wrapper.Legals, .modal-dialog.redView, .wrapper.Accounting, .wrapper.Files, .wrapper.Sepa, .wrapper.ConfigAccounting, .wrapper.ConfigAccountingKeys,.wrapper.ConfigBankAccounts {
	.loadFullWrap {
		i {
			color: $red;
		}
	}
	.plugin-list .plugin-item.headline .lineitem span.active{
		color: $red;
		+i{
			color: $red;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #b21f28;
		--bs-btn-border-color: #b21f28;
		--bs-btn-hover-bg: #b21f28;
		--bs-btn-hover-border-color: #b21f28;
		--bs-btn-active-bg: #b21f28;
		--bs-btn-active-color: #fff;
		--bs-btn-hover-color: #fff;
	}
	.btn-primary {
		--bs-btn-bg: #b21f28;
		--bs-btn-border-color: #b21f28;
		--bs-btn-hover-bg: #E0A5A9;
		--bs-btn-hover-border-color: #E0A5A9;
	}
	.form-check-input:checked {
		background-color: #b21f28;
		border-color: #b21f28;
	}
	.form-check-input:focus {
		box-shadow: 0 0 0 0.25rem rgb(204 0 38 / 25%);
	}
	.form-control:focus, .form-select:focus {
		border-color: #b21f28;
	}
	.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
		border-color: $red;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
		background-color: $red;
	}
	ul.tabNav {
		li {
			border-bottom-color: $red1;
			&.active {
				border-bottom-color: $red;
				color: $red;
			}
			&:hover {
				color: $red;
			}
		}
	}
	.multiselect-option.is-selected {
		background: $red;
		&:hover, &.is-pointed {
			background: $red;
		}
	}
	.card-header-inline {
		background: $red1;
	}
	ul li::marker {
		color: $red;
	}
	ol li::marker {
		color: $red;
	}
	label.color {
		color: $red1;
		display: block;
	}
	.infoRow {
		span i {
			cursor: pointer;
		}
		span:hover i {
			color: $red;
		}
	}
	div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
		background: $red;
		.multiselect__tag-icon {
			top: -2px;
			&:after {
				color: $light;
			}
		}
	}
	div.multiselect {
		.multiselect__select {
			border-color: $red;
		}
		&.multiselect--active {
			.multiselect__select {
				background: $red;
			}
		}
	}
	div.multiselect .multiselect__tags {
		border-color: $red1;
	}
	.dp__main.small .dp__pointer.dp__input {
		color: $red;
		border: 1px solid $red1;
	}
	.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__input_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
		color: $red;
		border: 1px solid $red;
	}
	hr {
		background: $red1;
	}
	.lineitem.last.ml-auto:hover i {
		color: $red;
	}
	.page-item:not(.disabled) .page-link {
		color: $red;
	}
	.page-item.active .page-link{
		color: $light;
		background: $red;
		border-color: $red;
	}
	.checkbox input[type=checkbox]:checked + label:before{
		border-color: $red;
	}
	.checkbox label:after{color: $red;}
	.fileList i:hover {
		color: $red;
	}
	b.color {
		color: $red !important;
	}
	.loadFullWrap {
		i {
			color: $red;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #b21f28;
		--bs-btn-border-color: #b21f28;
		--bs-btn-hover-bg: #b21f28;
		--bs-btn-hover-border-color: #b21f28;
	}
	span.btn-logout:hover {
		i {
			color: $red;
		}
	}
	p.color {
		color: $red1;
	}
	#filter_search {
		border-color: $red;
	}
	.topMainIcons .actionIcon.double {
		background: $red1;
	}
	.topMainIcons .actionIcon.double span.active {
		background: $red;
	}
	.topMainIcons .actionIcon.outter .btn i {
		color: $red;
	}
	div.multiselect .multiselect__select {
		border-color: $red;
	}
	div.multiselect.multiselect--active .multiselect__select {
		background: $red;
		&:before {
			border-color: $light transparent transparent transparent;
		}
	}
	div.multiselect.small .multiselect__single {
		color: $red;
	}
	div.multiselect .multiselect__content-wrapper {
		border-color: $red;
	}
	div.multiselect .multiselect__option--highlight {
		background: $red1;
	}
	div.multiselect .multiselect__select:before {
		border-color: $red transparent transparent transparent;
	}
	div.multiselect .multiselect__tags {
		border-color: $red1;
	}
	div.multiselect.multiselect--active .multiselect__tags {
		border-color: $red;
	}
	hr {
		background-color: $red1;
	}
	.navbarTop .input-group-append .btn {
		border-right-color: $red;
	}
	.lineitem.last.ml-auto:hover i {
		color: $red;
	}
	div.multiselect {
		.multiselect__select {
			border-color: $red;
		}
		&.multiselect--active {
			.multiselect__select {
				background: $red;
			}
		}
		.multiselect-caret {
			background-color: $red;
		}
		.multiselect-wrapper {
			border-color: $red;
		}
		.page-link.active, .active > .page-link {
			background-color: $red;
			border-color: $red;
			color: $light !important;
		}
	}
}

.appView.greenView, .wrapper.employees, .wrapper.EmployeeDetails, .wrapper.TimeSubOverview,.wrapper.StoryOverview,.wrapper.TicketTime, .wrapper.TicketTimeInvoices, .modal-dialog.greenView, .wrapper.TimesOverview, .wrapper.ConfigEmployee {
	.loadFullWrap {
		i {
			color: $green;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #3B8130;
		--bs-btn-border-color: #3B8130;
		--bs-btn-hover-bg: #3B8130;
		--bs-btn-hover-border-color: #3B8130;
		--bs-btn-active-bg: #3B8130;
		--bs-btn-active-border-color: #3B8130;
		--bs-btn-active-color: #fff;
		--bs-btn-hover-color: #fff;
	}
	.badge.badge-primary {
		background: $green;
	}
	.badge.badge-secondary {
		background: $green1;
	}
	.copyClip, .callNumber {
		color: $green;
	}
	.form-control:focus, .form-select:focus {
		border-color: #3B8130;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
		background-color: $green;
	}
	.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
		border-color: $green;
	}
	.btn-primary {
		--bs-btn-bg: #3B8130;
		--bs-btn-border-color: #3B8130;
		--bs-btn-hover-bg: #D5E1D3;
		--bs-btn-hover-border-color: #D5E1D3;
	}
	ul.tabNav {
		li {
			border-bottom-color: $green1;
			&.active {
				border-bottom-color: $green;
				color: $green;
			}
			&:hover {
				color: $green;
			}
		}
	}
	.multiselect-option.is-selected {
		background: $green;
		&:hover, &.is-pointed {
			background: $green;
		}
	}
	.checkbox {
		input[type=checkbox]:checked + label:before {
			border-color: $green;
		}
		label:after {
			color: $green;
		}
	}
	ol li::marker {
		color: $green;
	}
	.text-primary {
		color: $green !important;
	}
	.text-secondary {
		color: $green1 !important;
	}
	.card-header-inline {
		background: $green1;
	}
	ul li::marker {
		color: $green;
	}
	label.color {
		color: $green1;
		display: block;
	}
	.infoRow {
		span i {
			cursor: pointer;
		}
		span:hover i {
			color: $green;
		}
	}
	div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
		background: $green;
		.multiselect__tag-icon {
			top: -2px;
			&:after {
				color: $light;
			}
		}
	}
	div.multiselect {
		.multiselect__select {
			border-color: $green;
		}
		&.multiselect--active {
			.multiselect__select {
				background: $green;
			}
		}
		.multiselect-caret {
			background-color: $green;
		}
		.multiselect-wrapper {
			border-color: $green;
		}
	}
	div.multiselect .multiselect__tags {
		border-color: $green1;
	}
	.dp__main.small .dp__pointer.dp__input {
		color: $green;
		border: 1px solid $green1;
	}
	.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
		color: $green;
		border: 1px solid $green;
	}
	.dp__main.small .dp__pointer.dp__input, .dp__main .dp__pointer.dp__input {
		color: $green;
		border: 1px solid $green1;
	}
	.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons,
	.dp__main svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main svg.dp__icon.dp__input_icon.dp__input_icons {
		color: $green;
		border: 1px solid $green;
	}
	a{
		color: $green;
		&:hover{
			color: $green1;
		}
	}
	hr {
		background: $green1;
	}
	.lineitem.last.ml-auto:hover i {
		color: $green;
	}
	.page-item:not(.disabled) .page-link {
		color: $green;
	}
	.fileList i:hover {
		color: $green;
	}
	b.color {
		color: $green !important;
	}
	.loadFullWrap {
		i {
			color: $green;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #3B8130;
		--bs-btn-border-color: #3B8130;
		--bs-btn-hover-bg: #3B8130;
		--bs-btn-hover-border-color: #3B8130;
	}
	span.btn-logout:hover {
		i {
			color: $green;
		}
	}
	p.color {
		color: $green1;
	}
	#filter_search {
		border-color: $green;
	}
	.topMainIcons .actionIcon.double {
		background: $green1;
	}
	.topMainIcons .actionIcon.double span.active {
		background: $green;
	}
	.topMainIcons .actionIcon.outter .btn i {
		color: $green;
	}
	div.multiselect .multiselect__select {
		border-color: $green;
	}
	div.multiselect.multiselect--active .multiselect__select {
		background: $green;
		&:before {
			border-color: $light transparent transparent transparent;
		}
	}
	div.multiselect.small .multiselect__single {
		color: $green;
	}
	div.multiselect .multiselect__content-wrapper {
		border-color: $green;
	}
	div.multiselect .multiselect__option--highlight {
		background: $green1;
	}
	div.multiselect .multiselect__select:before {
		border-color: $green transparent transparent transparent;
	}
	div.multiselect .multiselect__tags {
		border-color: $green1;
	}
	div.multiselect.multiselect--active .multiselect__tags {
		border-color: $green;
	}
	hr {
		background-color: $green1;
	}
	.navbarTop .input-group-append .btn {
		border-right-color: $green;
	}
	.lineitem.last.ml-auto:hover i {
		color: $green;
	}
	.page-link.active, .active > .page-link {
		background-color: $green;
		border-color: $green;
		color: $light !important;
	}
}
.appView.orangeView, .appView.dfb, #wrapper.DFBApprovals,#wrapper.DFBInstitutions, #wrapper.DFBPhases,#wrapper.DFBRequests {
	.loadFullWrap {
		i {
			color: $orange;
		}
	}
	//.btn-outline-primary {
	//	--bs-btn-color: #FFA500;
	//	--bs-btn-border-color: #FFA500;
	//	--bs-btn-hover-bg: #FFA500;
	//	--bs-btn-hover-border-color: #FFA500;
	//	--bs-btn-active-bg: #FFA500;
	//	--bs-btn-active-color: #fff;
	//	--bs-btn-hover-color: #fff;
	//}
	//.btn-primary {
	//	--bs-btn-bg: #FFA500;
	//	--bs-btn-border-color: #FFA500;
	//	--bs-btn-hover-bg: #FFA500;
	//	--bs-btn-hover-border-color: #FFA500;
	//}
	.form-control:focus, .form-select:focus {
		border-color: #FFA500;
	}
	hr{
		background: $orange1;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
		background-color: $orange;
	}
	.nice-select:active, .nice-select.open, .nice-select:focus, .nice-select:hover {
		border-color: $orange;
	}
	.btn-primary{
		--bs-btn-bg: #FD7E14FF;
		--bs-btn-border-color: #FD7E14FF;
		--bs-btn-hover-bg: #FD7E14FF;
		--bs-btn-hover-border-color: #FD7E14FF;
	}
	ul.tabNav {
		li {
			border-bottom-color: $orange1;
			&.active {
				border-bottom-color: $orange;
				color: $orange;
			}
			&:hover {
				color: $orange;
			}
		}
	}
	.multiselect-option.is-selected {
		background: $orange;
		&:hover, &.is-pointed {
			background: $orange;
		}
	}
	.card-header-inline {
		background: $orange1;
	}
	ul li::marker {
		color: $orange;
	}
	ol li::marker {
		color: $orange;
	}
	label.color {
		color: $orange1;
		display: block;
	}
	.infoRow {
		span i {
			cursor: pointer;
		}
		span:hover i {
			color: $orange;
		}
	}
	div.multiselect.isMultiselect.small .multiselect__tags .multiselect__tags-wrap .multiselect__tag {
		background: $orange;
		.multiselect__tag-icon {
			top: -2px;
			&:after {
				color: $light;
			}
		}
	}

	div.multiselect {
		.multiselect__select {
			border-color: $orange;
		}
		&.multiselect--active {
			.multiselect__select {
				background: $orange;
			}
		}
		.multiselect-caret {
			background-color: $orange;
		}
		.multiselect-wrapper {
			border-color: $orange;
		}
	}
	div.multiselect .multiselect__tags {
		border-color: $orange1;
	}
	.dp__main.small .dp__pointer.dp__input {
		color: $orange;
		border: 1px solid $orange1;
	}
	.dp__main.small svg.dp__icon.dp__clear_icon.dp__input_icons, .dp__main.small svg.dp__icon.dp__input_icon.dp__input_icons {
		color: $orange;
		border: 1px solid $orange;
	}
	hr {
		background: $orange1;
	}
	.lineitem.last.ml-auto:hover i {
		color: $orange;
	}
	.page-item:not(.disabled) .page-link {
		color: $orange;
	}
	.fileList i:hover {
		color: $orange;
	}
	b.color {
		color: $orange !important;
	}
	.loadFullWrap {
		i {
			color: $orange;
		}
	}
	.btn-outline-primary {
		--bs-btn-color: #FD7E14FF;
		--bs-btn-border-color: #FD7E14FF;
		--bs-btn-hover-bg:  #FD7E14FF;
		--bs-btn-hover-border-color: #FD7E14FF;
	}
	span.btn-logout:hover {
		i {
			color: $orange;
		}
	}
	p.color {
		color: $orange1;
	}
	#filter_search {
		border-color: $orange;
	}
	.topMainIcons .actionIcon.double {
		background: $orange1;
	}
	.topMainIcons .actionIcon.double span.active {
		background: $orange;
	}
	.topMainIcons .actionIcon.outter .btn i {
		color: $orange;
	}
	div.multiselect .multiselect__select {
		border-color: $orange;
	}
	div.multiselect.multiselect--active .multiselect__select {
		background: $orange;
		&:before {
			border-color: $light transparent transparent transparent;
		}
	}
	.checkbox label:after{
		color: $orange;
	}
	.checkbox input[type=checkbox]:checked + label:before{
		border-color: $orange;
	}
	div.multiselect.small .multiselect__single {
		color: $orange;
	}
	div.multiselect .multiselect__content-wrapper {
		border-color: $orange;
	}
	div.multiselect .multiselect__option--highlight {
		background: $orange1;
	}
	div.multiselect .multiselect__select:before {
		border-color: $orange transparent transparent transparent;
	}
	div.multiselect .multiselect__tags {
		border-color: $orange1;
	}
	div.multiselect.multiselect--active .multiselect__tags {
		border-color: $orange;
	}
	hr {
		background-color: $orange1;
	}
	.navbarTop .input-group-append .btn {
		border-right-color: $orange;
	}
	.lineitem.last.ml-auto:hover i {
		color: $orange;
	}
	.page-link.active, .active > .page-link {
		background-color: $orange;
		border-color: $orange;
		color: $light !important;
	}
}

i.green {
	color: $green;
}

i.red {
	color: $red;
}

.color-red {
	color: $red;
}

.color-green {
	color: $green;
}

.color-blue {
	color: $blue;
}

.color-purple {
	color: $purple;
}
