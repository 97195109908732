

$orange:  #fd7e14 !default;
.nav-category {

  width: 100%;

  .category-link {

    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    margin-top: 24px;
    padding-left: 0;
    padding-right: 1rem;
    color: #344767;
    font-size: 0.8rem;

    &.category-link-employees {

      border-bottom: .09rem solid #42a62b;

    }

    &.category-link-workflows {

      border-bottom: .09rem solid #7b1991;

    }

    &.category-link-accounting {

      border-bottom: .09rem solid #ff4e3d;

    }

    &.category-link-configuration {

      border-bottom: .09rem solid $orange;

    }

  }

}

