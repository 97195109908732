
.customer-list {
	.customer-item {
		&.headline {
			.customerLineWrap:hover {
				background: transparent;
			}
			.customerLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				@include font-size(14, 1.4);
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14, 1.4);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
				&.first {
					padding-top: 7px;
				}
			}
		}
	}
}

.customer-list {
	.ps {
		clear: both;
		&.mainscroller {
			&.active {
				opacity: .6;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
	.customer-item {
		min-width: 100%;
		float: left;
		&.activeCustomer {
			.customerLineWrap {
				border-radius: 0;
				margin-bottom: 0;
				background: $bgbox;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				&:hover {
					background: $bgbox;
				}
				.lineitem{
					display: inline-flex !important;

				}
			}
			> .card-body {
				border-top: 0;
				margin-bottom: 2px;
				> .card-header {
					border-radius: 0;
				}
				> .card-body {
					border-top: 0;
				}
			}
			.oposFormatted {
				ul.oposList {
					list-style: none;
					margin-left: 0;
					padding-left: 0;
					li {
						label {
							font-weight: 500;
							min-width: 205px;
							i {
								min-width: 22px;
								color: $link;
							}
						}
						.btn:hover {
							color: $light;
							i {
								color: $light;
							}
						}
						span {
							display: inline-block;
							text-align: right;
							width: 100px;
						}
						&.deadline {
							label i {
								color: $red;
							}
						}
					}
				}
			}
		}
		.customerLineWrap {
			margin-bottom: 0;
			transition: .5s all ease 0s;
			cursor: pointer;
			display: flex;
			align-items: center;
			width: 100%;
			border-bottom: 1px solid $border;
			span.customerInfo {
				@include font-size(11, 1.5);
				display: block;
				font-weight: normal;
				white-space: pre;
			}
			&:hover {
				background: $bgbox;
			}
			.lineitem {
				transition: .3s all ease 0s;
				padding-top: 8px;
				padding-bottom: 9px;
				padding-left: 6px;
				padding-right: 6px;
				min-width: 32px;
				flex-grow: 0;
				flex-shrink: 0;
				&.l-checkbox {
					margin-right: 10px;
					padding-left: 12px;
					padding-right: 6px;
				}
				&.l-avatar {
					text-align: center;
					padding-right: 12px;
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-number {
					padding-left: 12px;
					padding-right: 12px;
					width: 105px;

					text-align: center;
					display: none;
					@include font-size(14, 1.8);
					@media #{$large}{
						display: block;
					}
				}
				&.l-last-activity {
					width: 90px;
					text-align: left;
					display: none;
					@include font-size(12, 1.6);
					@media #{$medium}{
						display: block;
					}
				}
				&.l-tags {
					width: 220px;
					text-align: left;
					display: none;
					@media #{$hd}{
						display: block;
					}
				}
				&.fromPlugin {
					@include font-size(14, 1.8);
					&.l-kwel {
						width: 80px;
					}
					&.l-anlagen {
						width: 90px;
					}
					&.l-ppkw {
						width: 80px;
					}
					&.l-pgesamt {
						width: 121px;
					}
					display: none;
					@media #{$xlarge}{
						display: block;
					}
					&.pluginDfb {
						display: none;
						@media (min-width: 590px) {
							display: block;
							width: 65px;
						}
						@media #{$xlarge}{
							@include font-size(12, 1.8);
							width: 125px;
						}
						text-align: left !important;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						@include font-size(11, 1.8);
						&.l-licenseVer {
							@media #{$medium-max}{
								display: none;
							}
						}
						&.isSort {
							overflow: visible;
							white-space: normal;
							text-overflow: initial;
						}
					}
				}
				&.l-company {
					width: 265px;
					@media #{$small}{

						width: 280px;
					}
					font-weight: 500;
					@include font-size(14, 1.8);
					max-width: 100%;
					flex-direction: column;
					padding-right: 12px;
					position: relative;
					@media #{$xlarge}{
						width: 250px;
					}
					@media #{$hd}{
						width: 350px;
					}
					.mainSpan{
						@include font-size(14,1.4);
					}
				}
				&.l-birthday, &.l-createdon {
					@include font-size(14);
					width: 102px;
					display: none;
					@media #{$xlarge}{
						display: block;
					}
				}
				&.l-createdon {
					margin-right: 24px;
				}
				&.l-email {
					width: 200px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					@include font-size(14, 1.8);
					display: none;
					@media #{$large}{
						display: block;
					}
					button {
						white-space: nowrap;
						text-overflow: ellipsis;;
						overflow: hidden;
						max-width: 100%;
					}
				}
				&.l-country {
					width: 120px;
					span {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						display: block;
						max-width: 100%;
						@include font-size(14, 1.8);
					}
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-prio {
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-checkbox {
					display: none;
					@media #{$large}{
						display: block;
					}
				}
				&.l-akquise {
					text-align: center;
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.l-quad-actions{
					min-width: 160px;
					display: none;
					@media #{$large}{
						display: inline-flex;
					}
				}
				&.actions {
					min-width: 40px;
					display: none;
					@media #{$large}{
						display: inline-flex;
					}
					button {
						background: transparent;
						color: $link;
						border: 0;
						&:hover {
							color: $light;
							background: $link;
						}
						&.btn-danger, &.btn-red {
							color: $red;
							&:hover {
								color: $light;
								background: $red;
							}
						}
						&.btn-purple {
							color: $purple;
							&:hover {
								color: $light;
								background: $purple;
							}
						}
					}
				}
				&.l-state {
				}
				&.l-prio, &.l-akquise, &.l-state, &.l-type {
					width: 54px;
					text-align: center;
				}
				&.l-verband {
					@include font-size(14);
					width: 75px;
					&.license {
						width: 102px;
					}
				}
				&.l-type {
					text-align: center;
					display: none;
					@media #{$medium}{
						display: block;
					}
				}
				&.last {
					padding-right: 16px;
					@media #{$medium-max}{
						margin-left: auto !important;
					}
				}
			}
		}
	}
	&.card-body > .customer-item.activeCustomer {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		.customerLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		> .card-body {
			@include clear();
			border-radius: 0 0 $br $br;
			border-top: 0;
			margin-bottom: 12px;
			.card {
				border: 0;
			}
		}
		.customerContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
			.passwordInfoWrap {
				.lineitem {
					&.l-type {
						width: 130px;
					}
				}
			}
		}
	}
}

.inlinePasswordEdit {
	@include clear();
	padding-top: 12px;
	padding-bottom: 12px;
}

.documentWrap {
	clear: both;
	padding-top: 6px;
	.document {
		padding-top: 12px;
		transition: .5s all ease 0s;
		padding-bottom: 9px;
		cursor: pointer;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $border;
		&:hover, &.active {
			background: $bgbox;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}
			&.l-title {
				width: 320px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
				&.l-doctitle {
					width: 220px;
					@media #{$large}{
						width: 330px;
					}
					@media #{$xlarge}{
						width: 440px;

					}
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}
			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
			}
			&.l-type {
				text-align: center;
				width: 70px;
				.badge {
					width: 100%;
				}
			}
			&.l-action {
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				.fa-x {
					display: inline-block;
				}
			}
			&.last {
				padding-right: 16px;
			}
		}
	}
}

span.roundBadge {
	display: inline-block;
	width: 24px;
	height: 24px;
	text-align: center;
	border-radius: 100%;
	font-weight: bold;
	color: #fff;
}

.addresswrap {
	.entry {
		position: relative;
		.pinRightPos {
			position: absolute;
			right: 0;
			top: 0;
			min-width: auto !important;
			button {
				position: relative;
				z-index: 9;
			}
		}
		a, button {
			color: $base;
			&:hover {
				i {
					color: $link;
				}
			}
		}
		.btn {
			&:hover {
				i {
					color: $light;
				}
			}
		}
		@include font-size(14);
		p {
			@include font-size(14);
			i {
				width: 20px;
				margin-right: 0;
			}
		}
		.btn-group:not(.pinRightPos) i {
			width: 20px;
			text-align: left;
			&.fa-envelope {
				margin-right: 0;
			}
		}
		.btn-group.pinRightPos + * {
			padding-right: 38px;
		}
		.copyClip {
			i {
				&.fa-envelope {
					margin-right: 4px;
				}
			}
		}
		padding-bottom: 6px;
		border-bottom: 1px solid $border;
		margin-bottom: 6px;
		@include clear();
		// calc width 100% - 24px
		width: calc(100% - 24px);
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}
	.btn-group {
		clear: left;
		float: left;
		min-width: 92%;
	}
}

.infoWrapper {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	max-width: 100%;
	.info {
		background: $bgbox;
		padding: 6px;
		.infoType {
			border-bottom: 1px solid $border;
			font-weight: 500;
		}
	}
}

.lineitem.last.ml-auto {
	&:hover {
		i {
			color: $link;
		}
	}
}

.preInfoContent {
	white-space: pre;
}

.pageCounter {
	position: absolute;
	right: 12px;
	bottom: 22px;
}

.copyClip {
	color: $link;
	background: transparent;
	border: 0;
	margin-right: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	i {
		&.fa-mobile {
			padding-left: 3px;
		}
		&.fa-envelope {
			margin-right: 2px;
		}
	}
	&:hover {
		i {
			color: $link;
		}
	}
}

.callNumber {
	color: $link;
	background: transparent;
	border: 0;
	margin-right: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
}

.btn-group {
	.callNumber {
		opacity: .3;
	}
	&:hover {
		.callNumber {
			opacity: 1;
		}
	}
}

.mainContact {
	.spacerLine {
		padding-bottom: 12px;
		margin-bottom: 12px;
		border-radius: 0;
		width: 100%;
		> .btn-group, > button.copyClip {
			width: auto;
			clear: both;
			display: flex;
		}
	}
	a, button {
		@include font-size(16, 1.8);
		color: $base;
		&:hover {
			i {
				color: $blue;
			}
		}
	}
	p {
		width: 100%;
		clear: both;
	}
	i {
		width: 20px;
		text-align: left;
	}
	> .btn-group, > button.copyClip {
		width: auto;
		clear: both;
		display: flex;
	}
	.noticesPreviewBox {
		max-height: 250px;
		overflow-y: auto;
		overflow-x: hidden;
		.btn {
			width: auto;
			text-align: center;
			i {
				width: auto;
			}
			&:hover {
				i {
					color: $light;
				}
			}
		}
	}
	button.copyClip, .callNumber {
		overflow: visible;
		white-space: normal;
		text-align: left;
	}
}

.customerTabs {
	@include clear();
	@include font-size(14, 1.8);
	ul.dropdown-menu {
		li {
			.dropdown-item:hover {
				background: $blue4;
			}
		}
	}
	span.file-uploads {
		display: none;
	}
}

.dropdown {
	ul.dropdown-menu {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
		border: 0;
	}
	.dropdown-toggle {
		&:after {
			transition: .3s all ease 0s;
		}
		&.active:after {
			transform: rotate(180deg);
		}
	}
}

.activityWrap {
	@include clear();
	.row {
		margin-left: 0;
		margin-right: 0;
		border-bottom: 1px solid $border;
		padding-bottom: 12px;
	}
	> .row {
		padding-bottom: 0;
	}
}

.customerContent {
	div.card-header {
		background: $blue3;
		border-bottom: 0;
	}
	h6.card-header {
		display: flex;
		align-items: center;
		background: $blue3;
		border-bottom: 0;
		i:not(.fa-user-edit) {
			margin-right: 6px;
			margin-top: 2px;
		}
		i.fa-file-signature {
			margin-right: 1px;
		}
		> button, > .btn, .dropdown > .btn {
			margin-left: auto;
			margin-bottom: 0;
		}
		.btn-group {
			margin-left: auto;
		}
	}
	.card > .card-header {
		border-radius: 0;
	}
	.card-body.hasSubtabs {
		padding: 0;
	}
}

.ps {
	clear: both;
	&.mainscroller {
		&.active {
			opacity: .6;
			&:hover {
				opacity: 1;
			}
		}
	}
}

.modal-content .edit-customer-card {
	min-height: 90vh;
}

.paginationWrapper {
	.nice-select {
		.list {
			top: auto;
			bottom: 100%;
			margin-bottom: 1px;
		}
	}
}

.modal-content {
	.addContact {
		label {
			@include font-size(14);
		}
	}
}

.previewCustomers {
	background: #fff;
	width: 20%;
	padding: 12px;
	font-size: 13px;
	float: right;
	margin-top: 59px;
	overflow-y: auto;
	max-height: 80vh;
}

.previewCustomers + .tab-content {
	width: 80%;
}

.appView.customers.reports {
	.table-responsive {
		max-height: 84vh;
		table {
			thead {
				th {
					position: sticky;
					top: 0px;
					background: $light;
					white-space: nowrap;
					max-width: 120px;
					text-overflow: ellipsis;
					overflow: hidden;
					z-index: 1;
					border-bottom: 1px solid $link;
				}
			}
		}
	}
}
.mailinline{
	.mailListingCustomer{
		flex-wrap: wrap;
	}

		.col-9.justify-content-left.align-self-center {
			display: flex;}

		.lineitem.l-titlesmall, .lineitem.l-type, .lineitem.l-state , .lineitem.l-task  {
			width: 35px;
		}
		.lineitem.l-mails {
			@media #{$large}{

				width: 250px;
			}
			@media #{$hd}{
				width: 400px;
			}
		}

		.lineitem .fa-check-circle, .lineitem .fa-id-card-clip {
			color: #3b8130;
		}
		.lineitem.l-containerTime{
			@media #{$medium-max}{
				width: 100%;
			}
			width: 360px;
			display: flex;
			@media #{$hd}{
				width: 500px;
			}
		}
		.lineitem.l-datetime {
			@media #{$medium-max}{

				width: 33.3333%;
			}
			width: 120px;
			@media #{$hd}{
				width: 180px;
			}
		}

		.lineitem .fa-file-contract,
		.lineitem .fa-snake,
		.lineitem .fa-users {
			color: #08c;
		}

}
