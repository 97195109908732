body, html {
	font-size: var(--base-font-size);
	height: 100%;
}

body {
	background: $light;
	max-height: 100vh;
	max-width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
main.main-content{
	min-height: 100vh;
}
::-webkit-scrollbar {
	width: 12px;
	height: 12px;
	background: $border;
	border-radius: $br $br $br $br;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: $br;
}
::-webkit-scrollbar-corner{
border-radius: $br;
}

// Main Layout
main.main-content {
	flex-grow: 1;
	height: 100%;
	position: relative;
	display: block;
	overflow-x: auto;
}
tr.lastday{
	td{
		border-bottom-width: 3px;
	}
}
.tab-content {
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
}
.perfect-scrollbar {
	overflow-x: hidden;
	overflow-y: scroll;
}
.appContainer {
	padding-left: 24px;
	padding-right: 24px;
	@media #{$hd}{
		padding-left: 48px;
		padding-right: 48px;
	}
	.card.mainWrap {
		border: 0;
		border-radius: 0;
	}
}
.limitBox > select{
	min-width: 75px;
}
.ck.ck-powered-by {
	display: none !important;
}
.productItem.activeProduct h6.inline{
	display: inline-block;
	margin-top: 6px;
	margin-bottom: 6px;
}
#wrapper {
	align-items: flex-start;
	&.Signin {
		> main {
			width: 100%;
		}
	}
	main.main-content {
		flex-grow: 1;
	}
}

nav#navbarBlur {
	margin: 0 !important;
	background: #fff;
	margin-bottom: 24px !important;
}

.badge {
	background: $blue;
	font-weight: normal;
	@include font-size(14, 1);
	border-radius: 7px;
&.badge-xs{
	@include font-size(12, 1);
}
}

.modal-dialog.modal-fullscreen {
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
}

.ps {
	.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.text-xs {
	font-size: .8rem;
}

ul.r-nav {
	margin-bottom: 0;
}

.loader__message {
	@include font-size(18);
	font-weight: normal;
}

ol.breadcrumb {
	margin-left: 0;
	margin-bottom: 0;
}

.btn-primary {
	color: $light;
	&:hover {
		color: $light;
	}
}

nav.breadcrumb {
	padding-top: 4px;
}
nav.navbarTop.navbar{
	ul.dropdown-menu{
		li{
			button{
				@include font-size(14, 1.8)
			}
		}
	}
}
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff; /* change if the mask should be a color other than white */
	z-index: 99999; /* makes sure it stays on top */
	.loader {
		width: 640px;
		height: 200px;
		max-width: 100%;
		margin-top: 150px;
		margin-left: auto;
		margin-right: auto;
		@media #{$large}{
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -320px;
			margin-top: -170px;
		}

		text-align: center;
		font-weight: bold;
		padding: 12px;
		background: $light;
		.loadbarwrap {
			width: 100%;
			display: block;
			background: #e7f0fe;
			.loadbar {
				background: $link;
				height: 28px;
				display: block;
				transition: .3s all ease 0s;
			}
		}
		.loader__message {
			margin-bottom: 0;
			@include font-size(20);
		}
		.loader__figure {
			@include font-size(28);
			color: $link;
			font-weight: lighter;
		}
	}
}

.navbox.side {
	background: $bgbox;
	padding: 12px;
	@media #{$hd}{
		padding: 24px;
	}
	ul.quickactions {
		margin-left: 0;
		margin-bottom: 0;
		padding: 0;
		li {
			list-style: none;
			padding: 0;
			margin: 0;
			.btn {
				width: 100%;
				white-space: nowrap;
			}
		}
	}
}

.wordBox {
	position: absolute;
	bottom: 4%;
	text-align: left;
	left: 86px;
	h5 {
		@include font-size(40);
		font-weight: lighter;
	}
	.introline {
		font-weight: lighter;
		@include font-size(24);
	}
}

// Signup
main.signup {
	.card {
		border: 0;
		border-radius: 0;
		.card-header {
			background: transparent;
			border-bottom: 0;
			h1 {
				color: $blue;
			}
		}
		.input-group i {
			color: $blue;
		}
		.input-group {
			margin-bottom: 12px;
			.input-group-text {
				position: absolute;
				z-index: 99;
				height: 100%;
				background: transparent;
				border: 0;
				& + input {
					padding-left: 42px;
					margin-bottom: 0;
					border-radius: $br !important;
				}
			}
		}
	}
}

.loadFullWrap{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	background-color: #ffffffde;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 44px;
	&.inner{
		height: 100%;
	}
}
.modal-body .loadFullWrap{
	position: relative;

}
.card .card{
	border-radius: 0;
	border: 0;
	.card-header{
		border-radius: 0;
	}
}
.appView > .card{
	margin-bottom: 24px;
}

.monthFilterWrap{
	float: right;
	width: 50%;
	*{
		@include font-size(14);
	}
	.col{
		margin-bottom: 0 !important;
	}
}
button[data-cke-tooltip-text='Insert image'] {
	display: none !important;
}
.nomb{
	*{
		margin-bottom: 0;
	}
}
button.btn-xs.clearCustomer {
	line-height: .8;
}
.brandingLogo {
	width: 75px;
	max-width: 100%;
	background: #fff;
	margin: 0 auto;
	@media #{$large}{
		border-radius: 6px;
	}
	overflow: hidden;
	text-align: center;
	img{
		width: 50px;
		max-width: 100%;
		display: inline-block;
	}
}
.ck.ck-editor{
	p{
		margin-bottom: 6px;
	}
}
.ck.ck-balloon-panel.ck-balloon-panel_visible {
	display: block;
	z-index: 99999;
}
.taskSuccessAnimation {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 600px;
	z-index: 1000;
	transform: translateY(100%) translateX(0);
	transition: .3s all ease 0s;
	opacity: 0;
	&.active {
		opacity: 1;
		transform: translateY(0) translateX(0);
	}
}
.form-floating:not(.form-control:disabled)::before{
	background: transparent;
}
.page-link{
	@media #{$small-max}{
		padding: 6px;
	}
}

.attachments{
	> ul{
		display: flex;
		margin-left: 0;
		padding: 0;
		flex-wrap: wrap;
		li{
			margin-left: 0;
			padding: 0;
		}
	}
}
