
@import "./assets/scss/styles.scss";

.Vue-Toastification__container {
  z-index: 9999999 !important;
}

embed#flipable {
  max-width: 100%;
  height: auto;
}

