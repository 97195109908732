
.kpi {
  margin-bottom: 12px;
  .progressBar {
    height: 10px;
    background-color: #f8f9fa;

    .progressBarValue {
      height: 100%;
      max-width: 100%;
      background-color: #3B8130;

    }
  }
}
span.iconBig {
  display: block;
  font-size: 32px;
  min-height: 52px;
  color: #3B8130;
}
.sumHeight .sumGroup {
  min-height: 100px;
  overflow: hidden;
}
.col-12>.sumGroup{
  min-height: 212px;
}
.sumGroup {



  .sumGroupValue, .sumGroupTitle {
    text-align: center;
  }

  .small,.sumGroupTitle {
    font-size: 14px;
    color: #6c757d;
  }
  .sumGroupValue {
    padding-top: 12px;
    padding-bottom: 0;
    font-size: 24px;
    font-weight: bold;
  }

  margin: 6px;
  margin-bottom: 12px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  background-color: #f8f9fa;
}

.issueTime {
  span.small {
    font-size: 0.8em;
    color: #6c757d;
  }

  td {
    vertical-align: top;
  }

  .fa-credit-card.active {
    color: #3B8130;
  }

}
