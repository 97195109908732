.helpbox{
	width: 100%;
	.helpbox__header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px 12px;
		background-color: $light;
		position: relative;
		 border-radius: 4px;
		.helpbox__title{
			@include font-size(15, 1.5);
			font-weight: 400;
			display: flex;
			margin-bottom: 0;
			cursor: pointer;
			width: 100%;
			i{
				margin-right: 12px;
				font-size: 24px;
			}
		}
		.helpbox__close{
			margin-left: auto;
			@include font-size(20,1);
			background: transparent;
			i{
				@include font-size(20,1);
			}
		}
		&.open{
			.helpbox__title{
				color: $light;
				i{
					color: $light;
				}
			}
		}
	}
	.helpbox__content .content{
		padding: 12px;
		@media #{$large}{
			padding: 12px 24px;
		}
		label{
			@include font-size(14);
		}
	}
	&.orange{
		.helpbox__header.open{
			background-color: $orange;
			color: $white;
			.helpbox__title{
				color: $light;
			}
			~ .helpbox__content{
				background-color: $orange2;
			}
		}
		.helpbox__header{
			border-color: $orange;
			background-color: $orange2;
			.helpbox__title{
				color: $orange;
			}
			i{
				color: $orange;
			}
		}
		li::marker{
			color: $orange !important;
		}
		b {
			color: $orange;
		}
	}
	&.red{
		.helpbox__header.open{
			background-color: $red;
			color: $white;
			.helpbox__title{
				color: $light;
			}
			~ .helpbox__content{
				background-color: $red2;
			}
		}
		.helpbox__header{
			border-color: $red;
			background-color: $red2;
			.helpbox__title{
				color: $red;
			}
			i{
				color: $red;
			}
		}
		li::marker{
			color: $red !important;
		}
		b{
			color: $red;
		}
	}
	&.green{
		.helpbox__header.open{
			background-color: $green;
			color: $white;
			.helpbox__title{
				color: $light;
			}
			~ .helpbox__content{
				background-color: $green2;
			}
		}
		.helpbox__header{
			border-color: $green;
			background-color: $green2;
			.helpbox__title{
				color: $green;
			}
			i{
				color: $green;
			}
		}
		li::marker{
			color: $green !important;
		}
		b {
			color: $green;
		}
	}
	&.blue{
		.helpbox__header.open{
			background-color: $blue;
			color: $white;
			.helpbox__title{
				color: $light;
			}
			~ .helpbox__content{
				background-color: $blue2;
			}
		}
		.helpbox__header{
			border-color: $blue;
			background-color: $blue2;
			.helpbox__title{
				color: $blue;
			}
			i{
				color: $blue;
			}
		}
		li::marker{
			color: $blue !important;
		}
		 b {color: $blue;}
	}
	&.purple{
		.helpbox__header.open{
			background-color: $purple;
			color: $white;
			.helpbox__title{
				color: $light;
			}
			~ .helpbox__content{
				background-color: $purple2;
			}
		}
		.helpbox__header{
			border-color: $purple;
			background-color: $purple2;
			.helpbox__title{
				color: $purple;
			}
			i{
				color: $purple;
			}
		}
		li::marker{
			color: $purple !important;
		}
		b {
			color: $purple;
		}
	}


}
.smallHelpBox{
	display: block;
	border-left: 5px solid #ddd;
	margin-bottom: 24px;
	padding-left: 12px;
	@include font-size(14);
	i{
		color: #ddd;
	}
	&.red{
		border-color: $red;
		i{
			color: $red;
		}
		li::marker{
			color: $red !important;
		}
	}
	&.green{
		border-color: $green;
		i{
			color: $green;
		}
		li::marker{
			color: $green !important;
		}
	}
	&.blue{
		border-color: $blue;
		i{
			color: $blue;
		}
		li::marker{
			color: $blue !important;
		}
	}
	&.purple{
		border-color: $purple;
		i{
			color: $purple;
		}
		li::marker{
			color: $purple !important;
		}
	}
	&.orange{
		border-color: $orange;
		i{
			color: $orange;
		}
		li::marker{
			color: $orange !important;
		}
	}
}
