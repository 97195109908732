
.product-list {
	.product-item {
		&.headline {
			display: none;
			@media #{$medium}{
				display: block;
			}
			.productLineWrap:hover {
				background: transparent;
			}
			.productLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				&.first {
					padding-top: 0 !important;
				}
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}
	}
}

.productList, .inDocument {
	.productItem {
		.productInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			cursor: pointer;
			border-bottom: 1px solid $border;
			&.inline {
				input[type='text'], input[type='number'], select {
					background: transparent;
					border: 0;
				}
			}
			li {
				&.arrow {
					width: 24px;
					text-align: center;
					@include font-size(16, 1.5);
					margin-right: 12px;
				}
				&.title {
					width: 250px;
				}
				&.quantity {
					margin-left: 12px;
					text-align: center;
				}
				&.price {
					margin-left: auto;
					text-align: left;
					font-weight: bold;
					color: $dark;
				}
				&.unit {
					margin-left: 6px;
					@include font-size(12, 2);
				}
				input {
					height: 20px;
				}
				&.tax {
					select {
						padding-right: 0;
					}
				}
			}
		}
	}
}

.inDocument {
	.input-group {
		padding-right: 16px;
		input.fullPriceRender {
			height: 20px;
			font-size: 16px;
			margin-left: auto;
			font-weight: bold;
			text-align: right;
		}
		select {
			height: 20px;
			width: 100%;
			font-size: 14px;
		}
	}
	.productList {
		&.currentlyDragging {
			.productItem {
				height: 68px;
				overflow: hidden;
			}
		}
	}
	.productListHeadline {
		ul.productInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			li {
				padding-left: 6px;
				font-size: 12px;
				color: $grey;
				display: inline-flex;
				align-items: center;
				&.quantity {
					width: 90px;
				}
				&.price {
					width: 100px;
					margin-left: 0;
				}
				&.unit {
					width: 100px;
				}
				&.tax {
					width: 100px;
				}
				&.title {
					width: 456px;
				}
				&.final_price {
					width: 124px;
					text-align: right;
					justify-content: right;
				}
				&.discount {
					width: 32px;
				}
				&.optional {
					width: 32px;
				}
			}
		}
	}
	.productWrap:last-child {
		border-bottom: 1px solid $border;
	}
	.productWrap {
		&.spacer {
			background: $blue3;
			ul.productInfoBar.inline.active {
				background: $blue;
				* {
					color: $light !important;
				}
			}
		}
		&.required {
			background: $red2;
			ul.productInfoBar.inline.active {
				background: $red;
				* {
					color: $light !important;
				}
			}
		}
		&.optional {
			background: $green2;
			ul.productInfoBar.inline.active {
				background: $green;
				* {
					color: $light !important;
				}
			}
		}
		&.folder {
			.productItem ul.productInfoBar {
				li.final_price {
					margin-left: auto;
				}
				li.title {
					width: 828px;
				}
			}
		}
	}
	.productItem {
		position: relative;
		border-bottom: 0;
		.dragButton {
			position: absolute;
			left: 6px;
			top: 32px;
		}
		.editorWrap {
			padding: 12px;
			@media #{$large}{
				padding: 24px;
			}
			border-left: 1px solid $border;
			border-right: 1px solid $border;
			border-top: 1px solid $border;
		}
		ul.productInfoBar {
			border: 1px solid $border;
			border-bottom: 0;
			&.active {
				background: $bg;
			}
			li {
				border-right: 1px solid $border;
				margin: 0;
				display: flex;
				align-items: center;
				padding-top: 6px;
				padding-bottom: 6px;
				&.drag {
					border: 0;
					width: 32px;
					justify-content: center;
					text-align: center;
				}
				&.type {
					width: 32px;
					border: 0;
					justify-content: right;
					text-align: right;
					i.fa-tags {
						color: $grey;
					}
				}
				input {
					max-width: 100%;
					width: 100%;
					height: 20px;
					font-size: 14px;
				}
				&.quantity {
					width: 90px;
					margin-left: 0;
				}
				&.price {
					width: 100px;
					margin-left: 0;
				}
				&.tax {
					width: 100px;
				}
				&.unit {
					width: 100px;
				}
				&.title {
					width: 400px;
				}
				&.discount {
					width: 32px;
				}
				&.optional {
					width: 32px;
				}
				&.final_price {
					border-right: 0;
					width: 124px;
					input {
						font-weight: bold;
						text-align: right;
					}
					span.folderSum {
						text-align: right;
						width: 100%;
						display: block;
						@include font-size(16);
						padding-right: 6px;
					}
				}
				&.action {
					border: 0;
					flex-grow: 1;
					padding-left: 24px;
					justify-content: center;
					span {
						padding-right: 6px;
						padding-left: 6px;
					}
				}
				label {
					margin: 0;
				}
				input[type='checkbox'] {
					opacity: 0;
					height: 0;
					width: 0;
					&:checked {
						+ i {
							color: $blue;
						}
					}
				}
			}
		}
	}
}

.appView.products {
	.productLineWrap {
		border-bottom: 1px solid $border;
		padding-top: 12px;
		transition: .5s all ease 0s;
		padding-bottom: 9px;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		&:hover, &.active {
			background: $bgbox;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding-left: 6px;
			padding-right: 6px;
			.fa-id-card-clip, .fa-check-circle {
				color: $green;
			}
			.fa-file-contract, .fa-users, .fa-snake {
				color: $blue;
			}
			.fa-circle-xmark, .fa-file-excel {
				color: $red;
			}
			.fa-clipboard-list-check {
				color: $purple;
			}
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
				min-width: 51px;
				.checkbox {
					width: 20px;
				}
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
				display: none;
				@media #{$xlarge}{
					display: block;
				}
			}
			&.l-icon {
				width: 32px;
				&.l-receiver {
					span.showLarge {
						display: none;
						@include font-size(12);
					}
					@media #{$hd}{
						width: 150px;
						display: flex;
						align-items: center;
						i {
							margin-right: 6px;
						}
						span.showLarge {
							display: inline-block;
						}
					}
					@media #{$hd}{
						width: 220px;
					}
				}
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				cursor: pointer;
				width: 175px;
				text-align: left;
				display: none;
				font-weight: normal;
				line-height: 1.4;
				@include font-size(14);
				@media #{$hd}{
					display: block;
				}
				display: none;
				font-weight: normal;
				@include font-size(14);
				@media #{$hd}{
					display: block;
				}
			}
			&.l-datetime {
				width: 100px;
				@include font-size(12, 1.2);
				span {
					@include font-size(12, 1.2)
				}
			}
			&.l-message {
				width: 200px;
				p {
					@include font-size(12, 1.2);
					margin-bottom: 0;
				}
			}
			&.l-title {
				width: 280px;
				@include font-size(14, 1.8);
				max-width: 100%;
				padding-right: 12px;
				position: relative;
				font-weight: 500;
				@media #{$large}{
					width: 520px;
				}
				&.l-doctitle {
					width: 600px;
				}
			}
			&.l-titlesmall {
				width: 220px;
				@include font-size(14, 1.8);
				font-weight: 500;
			}
			&.l-email {
				width: 200px;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				@include font-size(14, 1.8);
				width: 92px;
				display: none;
				@media #{$xlarge}{
					display: block;
				}
			}
			&.l-prio {
				padding-right: 24px;
				padding-left: 12px;
			}
			&.l-unit {
				margin-right: 25px;
				margin-left: 25px;
				@include font-size(14, 1.8);
				width: 100px;
				display: none;
				@media #{$medium}{
					display: block;
				}
			}
			&.l-quantity {
				@include font-size(14, 1.8);
				width: 100px;
				display: none;
				@media #{$xlarge}{
					display: block;
				}
			}
			&.l-action {
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				.fa-x {
					display: inline-block;
				}
			}
			&.l-job, &.l-date {
				display: none;
				@media #{$xlarge}{
					display: block;
				}
			}
			&.last {
				padding-right: 16px;
				margin-left: auto;
			}
		}
	}
	.product-list.card-body > .productItem.activeProduct {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		border-radius: 0 0 $br $br;
		.productLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		.card {
			.productLineWrap {
				display: flex;
				flex-wrap: wrap;
				.lineitem {
					display: block !important;
				}
			}
			@include clear();
			border: 0;
			border-top: 0;
			margin-bottom: 12px;
			.card-header {
				border-radius: 0;
			}
			.card-body {
				border: 0;
				border-top: 0;
				margin-bottom: 0;
				width: 100%;
			}
		}
		.productContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
	&.queue {
		.productLineWrap {
			@media #{$medium-max}{
				display: flex;
				flex-wrap: wrap;
			}
			.lineitem {
				&.l-mails {
					@include font-size(12);
					width: 284px;
				}
			}
		}
		.product-list {
			.product-item {
				&.headline {
					.productLineWrap:hover {
						background: transparent;
					}
					.productLineWrap {
						border-left-color: $light;
						* {
							color: $grey;
						}
					}
					.lineitem {
						&.first {
							padding-top: 0 !important;
						}
						> span + i {
							margin-left: 6px;
							color: $blue;
						}
						span {
							cursor: pointer;
							@include font-size(14);
						}
						span.active {
							color: $blue;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}

.documentContent {
	.productList {
		position: relative;
		width: 100%;
	}
}
