
.dfb.plugin-list table tr.edited td {
  background-color: #d4d4d4 !important;
  --bs-table-accent-bg: #d4d4d4 !important;
}

.ps {
  height: 400px;
}

.inline table td, .inline table th {
  white-space: normal;
  font-size: 14px;
  &:last-child {
    width: 32px;
  }
}

.inline table td:last-child {
  text-align: right;
}

embed {
  max-width: 100%;
  height: auto;
}

.checkedTrigger btn {
  padding: 3px 4px;
  width: 24px;
  height: 24px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
}

button.btn.btn-sm.btn-success.float-end.toggleAll {
  padding: 5px 12px;
}

.clearfix {
  float: left;
  width: 100%;
  clear: both;
  display: block;
}

.overviewTables th:last-child {
  width: 40px;
}

th.del {
  width: 35px;
}

th.begin-end {
  width: 107px;
}

th.endRight {
  width: 110px;
}

th.endRight, td.endRight {
  text-align: right;
}

table.table-sm th {
  font-size: 13px;
}

table.table-sm td {
  vertical-align: middle;
}

table.table-sm td:first-child {
  text-align: left;
  line-height: 14px;
}
.flipableWrapper{
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
tr.edited[data-del='true'] {
  opacity: 0.5;
}

